// API docs @see https://github.com/yiminghe/async-validator
import { Rules } from "async-validator";
import { isValidPhoneNumber } from "libphonenumber-js";
import { removeSpaces } from "@/shared/utils/stringHelper";
import { regex } from "@/shared/utils/constants";
import some from "lodash/some";

export const makeRequiredRule = (
  errorMessage: string,
  type: "string" | "array" | "number" | "boolean" | "object" = "string"
): {
  type: "string" | "array" | "number" | "boolean" | "object";
  message: string;
  required: boolean;
} => {
  return {
    type,
    required: true,
    message: errorMessage,
  };
};

/** Regular function for specified checking of regex string */
export const isRegexMatch = (
  text: string | undefined,
  regexStr: string
): boolean => {
  const regexObject = new RegExp(regexStr);
  let isValid = false;

  if (regexObject.test(text ?? "")) {
    isValid = true;
  } else {
    isValid = false;
  }
  return isValid;
};

export const makeRequiredInputNumber = (
  errorMessage: string
): {
  // eslint-disable-next-line no-unused-vars
  asyncValidator: (rule: Rules, value: number) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value: number) => {
      return new Promise<void>((resolve, reject) => {
        if (value <= 0 || value == null) {
          reject(new Error(errorMessage)); // reject with error message
        } else {
          resolve();
        }
      });
    },
  };
};

export const makeRegexRule = (
  errorMessage: string,
  regexString: string
): {
  // eslint-disable-next-line no-unused-vars
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  message: string;
} => {
  return {
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        const regexObject = new RegExp(regexString);
        if (regexObject.test(value)) {
          resolve();
        } else {
          reject(new Error(errorMessage));
        }
      });
    },
    message: errorMessage,
  };
};

export const makeMobileNumberRule = (
  errorMessage: string
): {
  // eslint-disable-next-line no-unused-vars
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        if (isValidPhoneNumber(removeSpaces(value))) {
          resolve();
        } else {
          reject(new Error(errorMessage));
        }
      });
    },
  };
};

export const makeEmailRule = (
  errorMessage: string
): {
  // eslint-disable-next-line no-unused-vars
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  message: string;
} => {
  return makeRegexRule(errorMessage, regex.email);
};

/** Regular function for specified checking of email string */
export const isValidEmail = (text: string | undefined): boolean => {
  const regexObject = new RegExp(regex.email);
  let isEmail = false;
  if (regexObject.test(text ?? "")) {
    isEmail = true;
  } else {
    isEmail = false;
  }

  return isEmail;
};

export const makeRequiredCheckedRule = (
  errorMessage: string
): {
  // eslint-disable-next-line no-unused-vars
  asyncValidator: (rule: Rules, value: boolean) => Promise<void>;
  type: string;
} => {
  return {
    type: "boolean",
    asyncValidator: (rule, value: boolean) => {
      return new Promise<void>((resolve, reject) => {
        if (value !== true) {
          reject(new Error(errorMessage)); // reject with error message
        } else {
          resolve();
        }
      });
    },
  };
};

/**
 * Validate for minimum length
 */
export const makeRequiredMinimumRule = (
  minLength: number,
  errorMessage: string
): {
  // eslint-disable-next-line no-unused-vars
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value) => {
      if (value.length >= minLength) {
        return Promise.resolve();
      } else {
        return Promise.reject(errorMessage);
      }
    },
  };
};

// Rule for Valid URL
export const makeUrlRule = (
  errorMessage: string
): {
  // eslint-disable-next-line no-unused-vars
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  message: string;
} => {
  return makeRegexRule(errorMessage, regex.url);
};

/** Regular function for specified checking of url string */
export const isValidUrl = (text: string | undefined): boolean => {
  const regexObject = new RegExp(regex.url);
  return regexObject.test(text ?? "");
};

/**
 * Validate if URL is part of domain
 */
export const makeURLDomainRule = (
  channelList,
  errorMessage: string
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value) => {
      if (isDomainUrl(channelList, value)) {
        return Promise.resolve();
      } else {
        return Promise.reject(errorMessage);
      }
    },
  };
};

// FIXME: isDomainUrl shouldn't require array of domain.value or object but a list of array
/** Regular function for specified checking if url is domain validated */
export const isDomainUrl = (channelList, link: string | undefined): boolean => {
  return some(channelList, (domain) => link?.includes(String(domain?.value)));
};
